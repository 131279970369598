export default {
	name: "WarrantsBulletin",
	created () {
		this.$nextTick(function () {
			// 获得数据
			this.GetDeployedAnnouncementList()
		});
	},
	mounted () {
		this.$nextTick(function () { })
	},
	data () {
		return {
			dataUl: [],
			dataContent: []
		};
	},
	computed: {

	},
	watch: {

	},
	methods: {
		// 获取数据
		GetDeployedAnnouncementList () {
			$.ajax({
				type: "POST",
				dataType: 'json',
				url: this.$$.mibb + "GetDeployedAnnouncementList",
				data: { lang: localStorage.getItem('lang') || 1 },
				async: false,
				success: (data) => {
					if (data.status == "SUCCESS") {
						if (data.text.length) {
							this.dataUl = data.text
							setTimeout(function () {
								$('#WarrantsBulletin>.mainContent>.scope>ul').find('li').eq(0).addClass('Announcementmark')
								let H1 = $('#WarrantsBulletin>.mainContent>.scope>ul').find('li').eq(0).outerHeight()
								let H2 = $('#WarrantsBulletin>.mainContent>.scope>ul').find('li').eq(1).outerHeight()
								let H3 = $('#WarrantsBulletin>.mainContent>.scope>ul').find('li').eq(2).outerHeight()
								if ($(window).width() <= 992) {
									$('#WarrantsBulletin>.mainContent>.scope>ul').css({
										'max-height': H1 + H2 + H3 + 30
									})
								} else {
									$('#WarrantsBulletin>.mainContent>.scope>ul').css({
										'max-height': ''
									})
								}
								$(window).resize(function () {
									H1 = $('#WarrantsBulletin>.mainContent>.scope>ul').find('li').eq(0).outerHeight()
									H2 = $('#WarrantsBulletin>.mainContent>.scope>ul').find('li').eq(1).outerHeight()
									H3 = $('#WarrantsBulletin>.mainContent>.scope>ul').find('li').eq(2).outerHeight()
									if ($(window).width() <= 992) {
										$('#WarrantsBulletin>.mainContent>.scope>ul').css({
											'max-height': H1 + H2 + H3 + 30
										})
									} else {
										$('#WarrantsBulletin>.mainContent>.scope>ul').css({
											'max-height': ''
										})
									}
								})
							}, 10);
							this.GetDeployedAnnouncement(data.text[0].id)
						}
					}
				}
			})
		},
		GetDeployedAnnouncement (id, index) {
			$('#WarrantsBulletin>.mainContent>.scope>ul').find('li').eq(index).addClass('Announcementmark')
			$('#WarrantsBulletin>.mainContent>.scope>ul').find('li').eq(index).siblings('li').removeClass('Announcementmark')
			$.ajax({
				type: "POST",
				dataType: 'json',
				url: this.$$.mibb + "GetDeployedAnnouncement",
				async: false,
				data: { id: id, lang: localStorage.getItem('lang') || 1 },
				success: (data) => {
					if (data.status == "SUCCESS") {
						this.dataContent = data.text[0]
					}
				}
			})
		}
	}
};